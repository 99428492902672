import axios from 'axios'
import store from '@/store/index.js'
import router from '../router'

const AUTH_URL = process.env.VUE_APP_AUTH_URL

class Auth {
    popup(myURL, title, myWidth, myHeight) {
        var left = (screen.width - myWidth) / 2
        var top = (screen.height - myHeight) / 4
        window.open(
            myURL,
            title,
            'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=' +
                myWidth +
                ', height=' +
                myHeight +
                ', top=' +
                top +
                ', left=' +
                left
        )
    }
    login(redirect = false) {
        const params = new URLSearchParams({
            client_id: process.env.VUE_APP_AUTH_CLIENT_ID,
            redirect_uri: process.env.VUE_APP_AUTH_REDIRECT_URL,
            response_type: 'code',
            scope: ''
        })
        const str = params.toString()
        const url = AUTH_URL + '/oauth/authorize?' + str

        if (redirect == true) {
            return url
        }

        if (this.isNotWithPopupCompatible()) {
            window.location.replace(url)
            return
        }

        this.popup(url, 'Autorisierung', 400, 400)
    }
    callback(request) {
        var that = this
        if (!request.query.code) {
            window.location.replace(this.login())
            return
        }

        axios
            .post(AUTH_URL + '/oauth/token', {
                grant_type: 'authorization_code',
                client_id: process.env.VUE_APP_AUTH_CLIENT_ID,
                client_secret: process.env.VUE_APP_AUTH_CLIENT_SECRET,
                redirect_uri: process.env.VUE_APP_AUTH_REDIRECT_URL,
                code: request.query.code
            })
            .then(function (response) {
                if (response.data.access_token) {
                    if (that.isNotWithPopupCompatible()) {
                        window.postMessage({ token: response.data }, process.env.VUE_APP_URL)
                        router.push({ name: 'Account' })
                        return
                    }
                    window.opener.postMessage({ token: response.data }, process.env.VUE_APP_URL)
                    window.close()
                }
            })
    }

    refreshToken() {
        axios
            .post(AUTH_URL + '/oauth/token', {
                grant_type: 'refresh_token',
                refresh_token: store.state.auth.refresh_token,
                client_id: process.env.VUE_APP_AUTH_CLIENT_ID,
                client_secret: process.env.VUE_APP_AUTH_CLIENT_SECRET,
                scope: ''
            })
            .then(function (response) {
                if (response.data.access_token) {
                    store.dispatch('auth/SET_ACCESS_TOKEN', response.data.access_token).then(() => {
                        store.dispatch('auth/SET_REFRESH_TOKEN', response.data.refresh_token).then(() => {
                            axios.get(AUTH_URL + '/api/user').then((response) => {
                                store.dispatch('auth/SET_USER', response.data).then(() => {
                                    window.location.replace(AUTH_URL + '/dashboard')
                                    // window.location.replace('https://localhost:8080')
                                })
                            })
                        })
                    })
                }
            })
    }

    logout() {
        axios.post(AUTH_URL + '/logout')
    }

    register() {
        return AUTH_URL + '/register'
    }

    isNotWithPopupCompatible() {
        return navigator.userAgent.toLowerCase().indexOf('firefox') > -1 || navigator.userAgent.match(/SamsungBrowser/i)
    }
}

export default new Auth()
