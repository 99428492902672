<template>
    <ul class="flex flex-wrap -mx-1 lg:-mx-4">
        <li v-for="(item, i) in appMenu" :key="i" class="my-1 px-1 w-1/2 lg:my-4 lg:px-4 lg:w-1/3">
            <router-link :to="{ name: 'Preview' }">
                <a href="#!" @click="setApp(item)">
                    <div class="h-32 relative rounded" :style="'background-color: ' + item.color">
                        <img
                            :src="item.icon"
                            class="
                                text-black
                                h-6
                                w-6
                                absolute
                                top-1/2
                                left-1/2
                                transform
                                -translate-x-1/2 -translate-y-1/2
                            "
                        />
                        <span class="truncate block w-full text-center text-white absolute bottom-0 pb-2 px-4">{{
                            item.title
                        }}</span>
                    </div>
                </a>
            </router-link>
        </li>
    </ul>
</template>

<script>
    import MenuAPI from '@/api/menu.js'
    export default {
        computed: {
            appMenu() {
                return this.$store.state.appMenu
            }
        },
        methods: {
            setApp(app) {
                if (app.target == '_blank') {
                    window.location.replace(app.url)
                }
                this.$store.dispatch('SET_APP', app)
            }
        },
        mounted() {
            MenuAPI.index().then(
                function (response) {
                    this.$store.dispatch('SET_APPMENU', response.data)
                }.bind(this)
            )
        }
    }
</script>

<style></style>
