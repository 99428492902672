<template>
    <div class="home">
        <app-header />
        <div class="container my-12 mx-auto px-4 md:px-12">
            <app-list />
        </div>
        <app-footer />
    </div>
</template>

<script>
    import AppFooter from '../components/AppFooter.vue'
    import AppHeader from '../components/AppHeader.vue'
    import AppList from '../components/AppList.vue'
    export default {
        components: { AppList, AppHeader, AppFooter },
        name: 'Home'
    }
</script>
