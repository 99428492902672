<template>
    <div id="app">
        <add-to-home-btn />
        <app-navigation v-if="!inIframe" />
        <transition>
            <router-view />
        </transition>
    </div>
</template>

<script>
    import AppNavigation from '@/components/AppNavigation'
    import AddToHomeBtn from './components/AddToHomeBtn.vue'
    export default {
        components: {
            AppNavigation,
            AddToHomeBtn
        },
        computed: {
            inIframe() {
                try {
                    return window.self !== window.top
                } catch (e) {
                    return true
                }
            }
        },
        mounted() {
            if (this.$store.getters['auth/isAuthenticated']) {
                navigator.serviceWorker.ready.then((registration) => {
                    registration.active.postMessage(this.$store.getters['auth/getUser'])
                })
            }
        }
    }
</script>

<style lang="scss"></style>
