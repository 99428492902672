<template>
    <div v-if="!isInStandaloneMode() && isMobile()" class="bg-white w-full">
        <div class="gab_app-nav-bar relative flex items-center justify-between h-12 box-border py-2">
            <img src="/img/icons/android-chrome-192x192.png" class="h-full object-contain rounded mr-3" alt="PWA" />
            <small class="items-end" v-show="isIos()"
                >Installiere die App auf deinem iOS-Gerät: Klicke auf
                <img
                    src="/img/icons/navigation_action.png"
                    class="inline"
                    style="width: 9.5px; height: 13.5px; margin-bottom: 3px"
                    alt="action"
                />
                und dann zum Startbildschirm hinzufügen</small
            >
            <small class="items-end" v-show="!deferredPrompt && !isIos() && !isSamsungBrowser()"
                >Installiere die App auf deinem Gerät: Klicke auf
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="inline h-3 w-3"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                >
                    <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                    />
                </svg>
                und dann Installieren</small
            >
            <small class="items-end" v-show="!deferredPrompt && isSamsungBrowser()"
                >Installiere die App auf deinem Gerät: Klicke auf
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="inline h-3 w-3"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    stroke-width="2"
                >
                    <path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
                und dann Seite hinzufügen zu App-Bildschirm</small
            >
            <button v-if="deferredPrompt" ref="addBtn" class="add-button px-4 rounded-xl" @click="clickCallback">
                Installieren
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        data: () => ({
            deferredPrompt: null
        }),
        computed: {},
        methods: {
            captureEvent() {
                window.addEventListener('beforeinstallprompt', (e) => {
                    // Prevent Chrome 67 and earlier from automatically showing the prompt
                    // e.preventDefault()
                    // Stash the event so it can be triggered later.
                    this.deferredPrompt = e
                })
            },
            clickCallback() {
                // Show the prompt
                this.deferredPrompt.prompt()
                // Wait for the user to respond to the prompt
                this.deferredPrompt.userChoice.then(() => {
                    // if (choiceResult.outcome === 'accepted') {}
                    this.deferredPrompt = null
                })
            },
            isInStandaloneMode() {
                return window.matchMedia('(display-mode: standalone)').matches
            },
            isIos() {
                return (
                    /iPad|iPhone|iPod/.test(navigator.platform) ||
                    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
                )
            },
            isSamsungBrowser() {
                return navigator.userAgent.match(/SamsungBrowser/i)
            },
            isMobile() {
                return window.matchMedia('only screen and (max-width: 992px)').matches
            }
        },
        mounted() {
            this.captureEvent()
        }
    }
</script>

<style scoped>
    .add-button {
        background-color: #eee;
    }
    .gab_app-nav-bar {
        padding: 0.5rem 15px !important;
    }
</style>
