/* eslint-disable no-console */

import { register } from 'register-service-worker'

import { Notyf } from 'notyf'
import 'notyf/notyf.min.css'
const notyf = new Notyf({
    types: [
        {
            type: 'info',
            background: '#1673b9',
            icon: false
        }
    ]
})

if (process.env.NODE_ENV === 'production') {
    /*register('firebase-messaging-sw.js', {
        ready() {
            console.log(
                'Cloudmessaging is ready'
            )
        },
        registered() {
            console.log('Cloudmessaging has been registered.')
        },
        error(error) {
            console.error('Error during cloudmessaging registration:', error)
        }
    })*/

    register(`${process.env.BASE_URL}firebase-messaging-sw.js`, {
        ready() {
            console.log(
                'App is being served from cache by a service worker.\n' +
                    'For more details, visit https://goo.gl/AFskqB'
            )
        },
        registered() {
            console.log('Service worker has been registered.')
            navigator.serviceWorker.addEventListener('message', event => {
                // event is a MessageEvent object
                console.log(`The service worker sent me a message: ${JSON.stringify(event.data)}`)
                if (event.data == "new push event") {
                    document.querySelector('.notifications-btn svg').style.fill = '#ff0000'
                }
            });
        },
        cached() {
            console.log('Content has been cached for offline use.')
        },
        updatefound() {
            console.log('New content is downloading.')

            notyf.open({
                type: 'info',
                message: 'Neue Inhalte werden geladen.',
                duration: 10000,
                dismissible: true
            })
        },
        updated() {
            console.log('New content is available; please refresh.')

            setTimeout(() => {
                window.location.reload(true)
            }, 1000)
        },
        offline() {
            console.log('No internet connection found. App is running in offline mode.')

            notyf.open({
                type: 'info',
                message: 'Sie haben kein Internet. Die App ist eingeschränkt nutzbar',
                duration: 10000,
                dismissible: true
            })
        },
        error(error) {
            console.error('Error during service worker registration:', error)
        }
    })
}
