import axios from 'axios'

export default {
    index() {
        return axios.get('https://altenberge.de/api/de/pwa/menu', {
            crossdomain: true,
            headers: {
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': '*',
                'Content-Type': 'application/json'
            }
        })
    }

    // show(id) {},

    // update(id, data) {},

    // create(data) {},

    // delete(id) {}
}
