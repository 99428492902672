<template>
    <div class="mobile-menu">
        <router-link to="/" class="h-full w-full" v-if="!isHome">
            <svg xmlns="http://www.w3.org/2000/svg" class="text-white h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path
                    d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                />
            </svg>
        </router-link>
        <a class="cursor-pointer" @click="settings" v-if="isHome && isAuthenticated">
            <svg xmlns="http://www.w3.org/2000/svg" class="text-white h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path
                    fill-rule="evenodd"
                    d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                    clip-rule="evenodd"
                />
            </svg>
        </a>
        <!-- <a href="#menu" v-if="isHome"
            ><svg
                xmlns="http://www.w3.org/2000/svg"
                class="text-white h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
            >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 6h16M4 12h16M4 18h16"
                /></svg
        ></a> -->
        <!-- <nav id="menu">
            <ul>
                <li><router-link to="/">Übersicht</router-link></li>
            </ul>
        </nav> -->
    </div>
</template>

<script>
    // import 'mmenu-js/dist/mmenu.css'
    // import Mmenu from 'mmenu-js'
    export default {
        computed: {
            isHome() {
                if (this.$route.name == 'Home') {
                    return true
                }
                return false
            },
            isAuthenticated() {
                return this.$store.getters['auth/isAuthenticated']
            }
        },
        methods: {
            settings() {
                this.$store.dispatch('SET_APP', {
                    title: 'Einstellungen',
                    color: '',
                    icon: '',
                    url: 'https://altenberge.de/admin/edit-userConfig.php'
                })
                this.$router.push({ name: 'Preview' })
            }
        },
        mounted() {
            // new Mmenu(document.querySelector('#menu'), {
            //     extensions: ['theme-white', 'pagedim-black']
            // })
            // document.addEventListener('click', (evnt) => {
            //     let anchor = evnt.target.closest('a[href^="#/"]')
            //     if (anchor) {
            //         // Go somewhere
            //         evnt.preventDefault()
            //     }
            // })
        }
    }
</script>

<style></style>
