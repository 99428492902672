export default {
    namespaced: true,
    state: {
        access_token: '',
        refresh_token: '',
        user: {}
    },
    getters: {
        isAuthenticated: (state) => {
            return Object.keys(state.user).length != 0
        },
        getUser: (state) => {
            return state.user
        }
    },
    mutations: {
        SET_ACCESS_TOKEN: (state, payload) => {
            state.access_token = payload
        },
        SET_USER: (state, payload) => {
            state.user = payload
        },
        SET_REFRESH_TOKEN: (state, payload) => {
            state.refresh_token = payload
        },
        DELETE_ACCESS_TOKEN: (state) => {
            state.access_token = ''
        },
        DELETE_REFRESH_TOKEN: (state) => {
            state.refresh_token = ''
        },
        DELETE_USER: (state) => {
            state.user = {}
        }
    },
    actions: {
        SET_ACCESS_TOKEN: ({ commit }, payload) => {
            commit('SET_ACCESS_TOKEN', payload)
        },
        SET_USER: ({ commit }, payload) => {
            commit('SET_USER', payload)
        },
        SET_REFRESH_TOKEN: ({ commit }, payload) => {
            commit('SET_REFRESH_TOKEN', payload)
        },
        DELETE_ACCESS_TOKEN: ({ commit }) => {
            commit('DELETE_ACCESS_TOKEN')
        },
        DELETE_REFRESH_TOKEN: ({ commit }) => {
            commit('DELETE_REFRESH_TOKEN')
        },
        DELETE_USER: ({ commit }) => {
            commit('DELETE_USER')
        }
    },
    modules: {}
}
