import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/store/auth.js'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
        appMenu: [],
        app: {}
    },
    mutations: {
        SET_APPMENU: (state, payload) => {
            state.appMenu = payload
        },
        SET_APP: (state, payload) => {
            state.app = payload
        }
    },
    actions: {
        SET_APPMENU: ({ commit }, payload) => {
            commit('SET_APPMENU', payload)
        },
        SET_APP: ({ commit }, payload) => {
            commit('SET_APP', payload)
        }
    },
    modules: {
        auth
    }
})
