<template>
    <nav class="bg-gradient-to-r from-primary to-secondary sticky top-0 z-10">
        <div class="gab_app-nav-bar">
            <div class="relative flex items-center justify-between h-16">
                <div class="shrink-0" style="min-width: 56px">
                    <app-menu />
                </div>
                <div class="h-8 shrink text-white text-lg font-bold truncate px-4">
                    <router-link to="/" class="h-8" v-if="!isPreview">
                        <img src="@/assets/images/logo.png" class="w-full h-full object-contain" />
                    </router-link>
                    <span v-if="isPreview" class="text-white text-lg font-bold truncate">{{ appTitle }}</span>
                </div>
                <div class="flex shrink-0">
                    <a @click="notifications" v-if="isAuthenticated" class="mr-4 cursor-pointer notifications-btn">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="text-white h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                        >
                            <path
                                d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z"
                            />
                        </svg>
                    </a>
                    <a @click="login" class="cursor-pointer">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="text-white h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                                clip-rule="evenodd"
                            />
                        </svg>
                    </a>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
    import Auth from '@/services/auth.js'
    import AppMenu from './AppMenu.vue'
    export default {
        components: { AppMenu },
        computed: {
            appTitle() {
                return this.$store.state.app.title
            },
            isPreview() {
                if (this.$route.name == 'Preview') {
                    return true
                }
                return false
            },
            isAuthenticated() {
                return this.$store.getters['auth/isAuthenticated']
            }
        },
        methods: {
            notifications() {
                this.$store.dispatch('SET_APP', {
                    url: 'https://altenberge.de/notification/pwa',
                    title: 'Benachrichtigungen',
                    icon: '',
                    color: '#357e48'
                })
                this.$router.push({ name: 'Preview' })
                document.querySelector('.notifications-btn svg').style.fill = ''
            },
            login() {
                if (!this.isAuthenticated) {
                    Auth.login()
                } else {
                    this.$router.push({ name: 'Account' })
                }
            }
        }
    }
</script>

<style>
    .gab_app-nav-bar {
        padding: 0 15px;
        max-width: 100%;
        margin: auto;
        box-sizing: content-box;
    }
    @media screen and (min-width: 576px) {
        .gab_app-nav-bar {
            max-width: 100%;
        }
    }
    @media screen and (min-width: 768px) {
        .gab_app-nav-bar {
            max-width: 720px;
        }
    }
    @media screen and (min-width: 992px) {
        .gab_app-nav-bar {
            max-width: 940px;
        }
    }
    @media screen and (min-width: 1200px) {
        .gab_app-nav-bar {
            max-width: 1140px;
        }
    }
</style>
