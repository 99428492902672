<template>
    <div class="container my-12 mx-auto px-4 md:px-12">
        <div class="mt-6 text-center">
            <a href="https://altenberge.de/de/impressum/pwa" target="_blank">Impressum</a> |
            <a href="https://altenberge.de/de/datenschutz/pwa" target="_blank">Datenschutz</a> |
            <a href="https://altenberge.de" target="_blank">Zur Website</a>
        </div>
    </div>
</template>

<script>
    export default {}
</script>

<style></style>
