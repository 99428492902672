import { render, staticRenderFns } from "./AddToHomeBtn.vue?vue&type=template&id=1dc9d590&scoped=true&"
import script from "./AddToHomeBtn.vue?vue&type=script&lang=js&"
export * from "./AddToHomeBtn.vue?vue&type=script&lang=js&"
import style0 from "./AddToHomeBtn.vue?vue&type=style&index=0&id=1dc9d590&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1dc9d590",
  null
  
)

export default component.exports