import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'
import Auth from '@/services/auth'

import 'tailwindcss/tailwind.css'

Vue.prototype.$http = axios
// const token = store.state.auth.access_token
// if (token) {
//     axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
// }

// Request interceptor for API calls
axios.interceptors.request.use(
    async (config) => {
        config.headers = {
            Authorization: `Bearer ${store.state.auth.access_token}`,
            Accept: 'application/json'
        }
        return config
    },
    (error) => {
        Promise.reject(error)
    }
)

// Response interceptor for API calls
axios.interceptors.response.use(
    (response) => {
        return response
    },
    async function (error) {
        const originalRequest = error.config
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true
            // store.dispatch('auth/DELETE_ACCESS_TOKEN')
            // store.dispatch('auth/DELETE_REFRESH_TOKEN')
            // store.dispatch('auth/DELETE_USER')
            const access_token = await Auth.refreshToken()
            console.log(access_token)
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token
        }
        return Promise.reject(error)
    }
)

const AUTH_URL = process.env.VUE_APP_AUTH_URL

var eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent'
var eventer = window[eventMethod]
var messageEvent = eventMethod == 'attachEvent' ? 'onmessage' : 'message'

// Listen to message from child window
eventer(
    messageEvent,
    (event) => {
        console.log(event)
        if (event && 'token' in event.data) {
            store.dispatch('auth/SET_ACCESS_TOKEN', event.data.token.access_token).then(() => {
                store.dispatch('auth/SET_REFRESH_TOKEN', event.data.token.refresh_token).then(() => {
                    axios.get(AUTH_URL + '/api/user').then((response) => {
                        store.dispatch('auth/SET_USER', response.data).then(() => {
                            // router.push('/')
                            sendUserToServiceWorker()
                        })
                    })
                })
            })
        }
        if (event && 'logout' in event.data) {
            store.dispatch('auth/DELETE_ACCESS_TOKEN')
            store.dispatch('auth/DELETE_REFRESH_TOKEN')
            store.dispatch('auth/DELETE_USER')
            router.push('/')
        }
    },
    false
)

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: (h) => h(App)
}).$mount('#app')

function sendUserToServiceWorker() {
    if (store.getters['auth/isAuthenticated']) {
        navigator.serviceWorker.ready.then((registration) => {
            registration.active.postMessage(store.getters['auth/getUser'])
        })
    }
}

/*setInterval(function () {
    const newLocal = document.querySelector('.notifications-btn svg').style.fill == ''
    if (newLocal && store.getters['auth/isAuthenticated']) {
        const localUser = store.getters['auth/getUser']
        const url = 'https://altenberge.de/ajax-request/get-unpushed-messages.php?users=' + localUser['id']
        axios.get(url).then(function (response) {
            if (
                response.data &&
                response.data[localUser['id']] &&
                response.data[localUser['id']].length > 0
            ) {
                Notification.requestPermission(function (status) {
                    if (status == 'granted') {
                        navigator.serviceWorker.ready.then(function (serviceWorkerRegistration) {
                            response.data[localUser['id']].forEach((element) => {
                                serviceWorkerRegistration.showNotification(element.title, {
                                    body: element.note
                                })
                            })
                        })
                    }
                })
                document.querySelector('.notifications-btn svg').style.fill = '#ff0000'
            }
        })
    }
}, 10000)*/
