<template>
    <div>
        <img
            src="@/assets/images/header.png"
            style="min-height: 150px; max-height: 300px"
            class="w-full object-cover"
        />
    </div>
</template>

<script>
    export default {}
</script>

<style></style>
